import {BikiniSection} from '../types/bikini'

import React, {useEffect, useRef, useState, StatelessComponent, CSSProperties} from 'react'
import styled from 'styled-components'
import jump from 'jump.js'

import {MOBILE_HEIGHT} from '../layout/header'
import Banner from '../components/banner'
import Down from '../images/down'

import {colors, MOBILE_BREAKPOINT} from '../helpers/constants'
import {isBrowser} from '../helpers/window'

const MENU_HEIGHT = 40
const STICKY_TOP = 85
const BOTTOM = 50

enum Position {
  top,
  sticky,
  bottom,
}

const POSITIONS = {
  [Position.top]: `position: absolute`,
  [Position.sticky]: `position: fixed; top: ${STICKY_TOP}px`,
  [Position.bottom]: `position: absolute; bottom: ${BOTTOM}px`,
}

const Container = styled.div`
  position: relative;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-top: 40px;
  }
`

const Menu = styled.div<{position: Position; open: boolean}>`
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 320px;
    ${(props) => POSITIONS[props.position]};
    svg {
      display: none;
    }
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    background: ${colors.primary};
    margin: 0 -10px;
    width: calc(100% + 20px);
    transition: ${(props) => (props.open ? 'height 0.3s' : 'none')};
    height: 50px;
    overflow: hidden;
    position: fixed;
    z-index: 3;
    top: ${MOBILE_HEIGHT}px;
    padding-top: ${MENU_HEIGHT}px;
    svg {
      position: absolute;
      top: 15px;
      right: 30px;
      stroke: ${colors.white};
      transition: ${(props) => (props.open ? 'transform 0.3s' : 'none')};
      transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
    }
  }
`
const Tab = styled.button<{current: boolean}>`
  display: block;
  text-align: left;
  padding: 15px 0;
  width: 100%;
  height: 35px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    ${(props) => (props.current ? 'position: absolute; top: 0;' : '')};
    height: ${MENU_HEIGHT}px;
    padding: 0 10px;
  }
`
const TabText = styled.div<{current: boolean}>`
  font-size: 1.25rem;
  font-weight: 600;
  vertical-align: top;
  overflow: hidden;
  transition-property: height, color;
  transition-duration: 0.1s;
  height: ${(props) => (props.current ? 22 : 16.5)}px;
  color: ${(props) => (props.current ? colors.red : colors.primary)};
  :hover {
    height: 22px;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    color: ${(props) => (props.current ? colors.red : colors.white)};
  }
`

const Sections = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-bottom: 50px;
  }
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 240px;
  }
  @media (min-width: 1050px) {
    margin-left: 340px;
  }
`
const Article = styled.article`
  padding: 60px 0;
  margin: -60px 0;
  .button {
    text-align: center;
    margin: 1.5rem 0;
  }
  button,
  .button a {
    background: ${colors.primary};
    color: ${colors.white};
    border-radius: 20px;
    padding: 8px 40px;
    font-weight: 600;
    display: inline-block;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 90px 0 0;
    margin: -80px 0 -90px;
    .button {
      margin: 0.75rem 0;
    }
  }
`
const TitleWrapper = styled.div`
  border-bottom: 5px solid ${colors.primary};
  text-align: right;
  clear: both;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    border-bottom-width: 3px;
  }
`
const Title = styled.h1`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 1.25rem;
    margin: 0.5em 0;
  }
`
const Content = styled.div`
  margin: 50px 0;
  line-height: 1.3rem;
  font-size: 0.9rem;
  text-align: justify;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 20px 0 30px;
    img[align='left'],
    img[align='right'] {
      margin: 0 0 10px;
      width: 100%;
      float: none;
      height: auto;
    }
  }
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    img[align='left'] {
      float: left;
      margin: 0 20px 10px 0;
    }
    img[align='right'] {
      float: right;
      margin: 0 0 10px 20px;
    }
  }
  iframe {
    border: none;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  td {
    border-bottom: 2px solid ${colors.white};
    padding: 12px 0;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    td {
      padding: 25px 0 5px;
      font-size: 0.75rem;
    }
    td:first-child {
      position: absolute;
      padding-top: 5px;
      border: none;
    }
  }
`

interface Props {
  sections: BikiniSection[]
  extras?: {[key: string]: StatelessComponent}
}

const SectionsTemplate: React.FC<Props> = ({sections, extras}) => {
  const ref = useRef<HTMLDivElement>()
  const menu = useRef<HTMLDivElement>()
  const timeout = useRef<number>()
  const scrolling = useRef<boolean>(false)
  const [position, setPosition] = useState<Position>(Position.top)
  const [tab, setTab] = useState(sections[0] && sections[0].slug)
  const [openMenu, setOpenMenu] = useState(false)

  const scrollTo = (target: string, callback?: () => void) => {
    jump(target, {
      duration: 500,
      callback,
      offset: 5,
      easing: null,
    })
    setTab(target.slice(1)) // do not wait to update menu
  }

  const handleTab = (id: string) => () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setOpenMenu(!openMenu)
      if (!openMenu || id === tab) {
        return
      }
    }
    const target = `#${id}`
    scrolling.current = true
    scrollTo(target, () => {
      scrolling.current = false
      history.replaceState({}, '', target)
    })
  }

  useEffect(() => {
    const handleScroll = () => {
      if (!sections.length || !ref.current || !menu.current) {
        return
      }
      if (window.innerWidth > MOBILE_BREAKPOINT) {
        const {top, bottom} = ref.current.getBoundingClientRect()
        const {height} = menu.current.getBoundingClientRect()
        if (top > 25) {
          setPosition(Position.top)
        } else if (bottom < height + STICKY_TOP + BOTTOM) {
          setPosition(Position.bottom)
        } else {
          setPosition(Position.sticky)
        }
      }

      const index = sections.findIndex(({slug}) => document.querySelector(`#${slug}`).getBoundingClientRect().top > 0)
      const {hash} = window.location
      if (index !== 0) {
        const current = sections[(index > 0 ? index : sections.length) - 1]
        const newHash = `#${current.slug}`
        if (newHash !== hash && !scrolling.current) {
          history.replaceState({}, '', `#${current.slug}`)
          setTab(current.slug)
        }
      }
    }

    const handleHash = () => {
      const {hash} = window.location
      if (hash) {
        scrollTo(hash)
      }
    }

    document.addEventListener('scroll', handleScroll, {passive: true})
    window.addEventListener('hashchange', handleHash)
    if (location.hash) {
      setTab(location.hash.substr(1))
      timeout.current = setTimeout(handleHash, 800)
    }
    handleScroll()
    return () => {
      document.removeEventListener('scroll', handleScroll)
      window.addEventListener('hashchange', handleHash)
      clearTimeout(timeout.current)
    }
  }, [])

  let menuStyle: CSSProperties
  if (!isBrowser() || window.innerWidth < MOBILE_BREAKPOINT) {
    menuStyle = {height: openMenu ? (sections.length - 1) * MENU_HEIGHT : 0}
  }

  return (
    <>
      <Container>
        <Banner />
        <Menu position={position} open={openMenu} style={menuStyle} ref={menu}>
          <Down />
          {sections.map(({slug, title}) => (
            <Tab key={slug} current={slug === tab} onClick={handleTab(slug)}>
              <TabText current={slug === tab}>{title}</TabText>
            </Tab>
          ))}
        </Menu>
        <Sections ref={ref}>
          {sections.map(({slug, title, content}) => {
            const Extra = extras && extras[slug]
            return (
              <Article key={slug} id={slug}>
                <TitleWrapper>
                  <Title>{title}</Title>
                </TitleWrapper>
                <Content dangerouslySetInnerHTML={{__html: content}} />
                {Extra && <Extra />}
              </Article>
            )
          })}
        </Sections>
      </Container>
    </>
  )
}

export default SectionsTemplate
