import React, {SVGAttributes} from 'react'

const Tickets: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg width={70} height={60} viewBox="0 0 70 60" {...props}>
    <g fill="#000" fillRule="evenodd">
      <path
        d="M69.933 25.635L61.866.94a1.37 1.37 0 0 0-1.723-.873L.945 19.203c-.718.232-1.111 1-.878 1.714l3.819 11.689c-.001.023-.004.045-.004.068V58.64c0 .751.613 1.36 1.368 1.36h62.245c.755 0 1.367-.609 1.367-1.36V32.674c0-.75-.612-1.36-1.367-1.36H56.787l12.268-3.966c.718-.232 1.111-.999.878-1.713zm-3.806 8.4V57.28H6.617V34.034h7.573v15.152c0 .75.612 1.36 1.367 1.36s1.367-.61 1.367-1.36V34.034h49.203zm-18.188-2.72H6.34L3.09 21.368l7.207-2.33 2.756 8.334a1.367 1.367 0 0 0 1.726.867 1.358 1.358 0 0 0 .872-1.717L12.897 18.2l46.79-15.125 7.223 22.108-18.97 6.132z"
        fillRule="nonzero"
      />
      <path d="M57.902 8.572a1.366 1.366 0 0 0-1.719-.87L20.63 19.177a1.352 1.352 0 0 0 .421 2.642c.14 0 .282-.021.422-.066l35.553-11.473a1.352 1.352 0 0 0 .876-1.707zM51.077 17.395l7.005-1.942c.745-.206 1.153-.89.91-1.525-.242-.636-1.042-.984-1.788-.778L50.2 15.092c-.746.207-1.154.89-.911 1.526.195.511.75.837 1.35.837.144 0 .293-.02.438-.06zM56.935 24.189c.17.551.65.902 1.168.902.126 0 .254-.02.38-.064l1.918-.663c.645-.222.998-.958.789-1.644-.21-.685-.903-1.06-1.548-.838l-1.918.663c-.645.222-.998.959-.789 1.644zM53.08 24.064l-1.919.662c-.645.223-.998.96-.788 1.645.169.551.65.902 1.168.902.125 0 .253-.021.38-.064l1.918-.663c.645-.222.998-.959.788-1.644-.21-.685-.902-1.06-1.548-.838zM34.292 20.792l-12.56 3.909c-.722.225-1.118.968-.883 1.66.19.558.728.912 1.309.912.14 0 .284-.021.425-.065l12.56-3.909c.722-.225 1.118-.968.883-1.66-.235-.693-1.012-1.072-1.734-.847zM15.86 52.364c-.907 0-1.641.612-1.641 1.368v.536c0 .756.734 1.368 1.64 1.368.906 0 1.641-.612 1.641-1.368v-.536c0-.756-.735-1.368-1.64-1.368zM21.875 39.818c0 .904.616 1.637 1.376 1.637h37.717c.76 0 1.376-.733 1.376-1.637 0-.903-.616-1.636-1.376-1.636H23.251c-.76 0-1.376.732-1.376 1.636zM60.975 42.545h-7.106c-.756 0-1.369.733-1.369 1.637 0 .903.613 1.636 1.37 1.636h7.105c.756 0 1.369-.733 1.369-1.636 0-.904-.613-1.637-1.37-1.637zM60.842 51.273h-2.466c-.829 0-1.501.732-1.501 1.636 0 .904.672 1.636 1.501 1.636h2.466c.83 0 1.502-.732 1.502-1.636 0-.904-.673-1.636-1.502-1.636zM53.186 51.273H50.72c-.829 0-1.501.732-1.501 1.636 0 .904.672 1.636 1.501 1.636h2.466c.83 0 1.502-.732 1.502-1.636 0-.904-.673-1.636-1.502-1.636zM35.867 42.545H23.196c-.73 0-1.321.733-1.321 1.637 0 .903.591 1.636 1.32 1.636h12.672c.73 0 1.32-.733 1.32-1.636 0-.904-.59-1.637-1.32-1.637z" />
    </g>
  </svg>
)

export default Tickets
