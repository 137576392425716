import React, {SVGAttributes} from 'react'

const Print: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg width={47} height={50} viewBox="0 0 47 50" {...props}>
    <g fill="#000" fillRule="evenodd">
      <path
        d="M39.693 10.249h-1.271v-2.86c0-4.073-3.28-7.389-7.307-7.389H15.773c-4.028 0-7.308 3.316-7.308 7.389v2.86H7.307C3.28 10.249 0 13.565 0 17.637v11.917c0 4.073 3.28 7.389 7.307 7.389h1.148v9.368c0 2.031 1.64 3.689 3.649 3.689h22.66c2.008 0 3.648-1.658 3.648-3.69v-9.367h1.28c4.028 0 7.308-3.316 7.308-7.389V17.637c0-4.072-3.28-7.388-7.307-7.388zm-28.47-2.86c0-2.529 2.039-4.591 4.54-4.591h15.331c2.5 0 4.54 2.062 4.54 4.59v2.86H11.222v-2.86zm24.422 38.943c0 .487-.4.89-.882.89h-22.66a.893.893 0 0 1-.88-.89V30.88h24.422v15.45zm8.588-16.767c0 2.528-2.04 4.59-4.54 4.59h-1.271v-3.274h1.824c.769 0 1.384-.622 1.384-1.4 0-.776-.615-1.398-1.384-1.398H6.426c-.769 0-1.384.622-1.384 1.399s.615 1.399 1.384 1.399h2.04v3.274H7.306c-2.5 0-4.54-2.062-4.54-4.59V17.648c0-2.529 2.04-4.591 4.54-4.591h32.386c2.5 0 4.54 2.062 4.54 4.59v11.918z"
        fillRule="nonzero"
      />
      <path d="M14.082 37.18h17.566c.766 0 1.379-.57 1.379-1.283 0-.712-.613-1.282-1.38-1.282H14.083c-.766 0-1.38.57-1.38 1.282 0 .713.624 1.282 1.38 1.282zM31.658 41.026H14.082c-.766 0-1.38.57-1.38 1.282 0 .712.614 1.282 1.38 1.282h17.566c.766 0 1.379-.57 1.379-1.282 0-.713-.613-1.282-1.37-1.282zM39.388 15.385h-2.56c-.7 0-1.26.57-1.26 1.282 0 .712.56 1.282 1.26 1.282h2.56c.7 0 1.26-.57 1.26-1.282 0-.713-.56-1.282-1.26-1.282z" />
    </g>
  </svg>
)

export default Print
