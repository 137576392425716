import React, {SVGAttributes} from 'react'

const Mail: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg width={68} height={40} viewBox="0 0 68 40" {...props}>
    <path
      d="M67.86 3.367C67.857 1.51 66.297 0 64.382 0h-.005L3.474.081a3.511 3.511 0 0 0-2.459.99A3.3 3.3 0 0 0 0 3.459l.047 33.175a3.3 3.3 0 0 0 1.022 2.383A3.511 3.511 0 0 0 3.525 40h.005l60.903-.081c1.918-.003 3.476-1.517 3.474-3.377L67.86 3.367zm-7.3.01L33.957 21.305 7.3 3.448l53.26-.071zM45.208 24.689l15.779 11.863-54.11.072 15.876-11.935c.759-.57.897-1.63.308-2.366a1.776 1.776 0 0 0-2.44-.299L3.523 34.878 3.48 4.988l29.49 19.756c.298.2.643.299.989.299.347 0 .694-.1.993-.302L64.384 4.907l.045 29.966-17.09-12.848a1.776 1.776 0 0 0-2.44.299 1.651 1.651 0 0 0 .308 2.365z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
)

export default Mail
