import {BikiniSection} from '../types/bikini'

import React from 'react'
import {graphql} from 'gatsby'
import styled from 'styled-components'

import Layout from '../layout'
import SectionsTemplate from '../templates/sections'
import Mobile from '../images/mobile'
import Print from '../images/print'
import Mail from '../images/mail'
import Tickets from '../images/tickets'
import Logo from '../images/logo'

import {colors, MOBILE_BREAKPOINT} from '../helpers/constants'
import {contentImages} from '../helpers/text'

const Method = styled.div`
  background: ${colors.white};
  padding: 10px 40px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  svg {
    float: right;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    svg {
      display: none;
    }
    padding: 10px;
    margin-bottom: 10px;
  }
`
const Text = styled.div`
  flex: 1;
  p {
    font-size: 0.9rem;
  }
`
const Title = styled.h2`
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 10px;
`
const Venue = styled(Logo)`
  width: 177px;
  height: 50px;
  margin-right: -30px;
`

const Infos: React.FC = () => (
  <>
    <Method>
      <Text>
        <Title>M-TICKET</Title>
        <p>Présentez votre mobile directement à l'entrée</p>
      </Text>
      <Mobile />
    </Method>
    <Method>
      <Text>
        <Title>E-TICKET</Title>
        <p>Imprimez votre ticket, présentez vous directement à l'entrée</p>
      </Text>
      <Print />
    </Method>
    <Method>
      <Text>
        <Title>
          GUICHET
          <br />
          retrait sur place
        </Title>
        <p>Retirez la place au guichet, uniquement le soir du concert</p>
      </Text>
      <Venue />
    </Method>
    <Method>
      <Text>
        <Title>
          RETRAIT EN MAGASIN
          <br />
          Gibert Joseph
        </Title>
        <p>
          Gibert Joseph Musique
          <br />
          Rez-de-chaussée au 3 rue du Taur, 31000 Toulouse
        </p>
      </Text>
      <Tickets />
    </Method>
    <Method>
      <Text>
        <Title>COURRIER</Title>
        <p>Votre billet directement dans votre boîte à lettres</p>
      </Text>
      <Mail />
    </Method>
  </>
)

const extras = {
  infos: Infos,
}

const BilletteriePage = ({data}) => {
  const sections: BikiniSection[] = data.allBikiniSection.edges.map(contentImages)

  return (
    <Layout title="Billetterie">
      <SectionsTemplate sections={sections} extras={extras} />
    </Layout>
  )
}

export default BilletteriePage

export const query = graphql`
  query {
    allBikiniSection(filter: {page: {eq: "billetterie"}}, sort: {fields: [order], order: ASC}) {
      edges {
        node {
          slug
          title
          content
          childrenFile {
            childImageSharp {
              fluid(maxWidth: 1320) {
                # TODO: only create one version
                src
              }
            }
            url
          }
        }
      }
    }
  }
`
